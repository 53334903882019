import React from "react";
import Box from "./Box";
import arrow from "../assets/arrow.svg";
import Userform from "./Userform";
import star from "../assets/star-yellow.svg";
import arrow_violet from "../assets/arrow-violet.svg";

const Contacts = () => {
  return (
    <section id="contacts">
      <div className="py-16 md:grid md:grid-cols-2 md:gap-x-8">
        <div className="md:ml-8">
          <h1 className="text-th-grey">READY FOR THE NEXT STEP?</h1>
          <img className="w-16 mt-2 md:hidden" src={arrow} alt=""></img>
          <img className="w-24 mt-2 hidden md:block mx-auto" src={arrow_violet} alt=""></img>
          <img className="mt-12 w-12 hidden md:block" src={star} alt=""></img>
          <img className="w-24 ml-8 hidden md:block" src={star} alt=""></img>
        </div>
        <Box styles="mt-16 md:mt-0 bg-th-grey py-16 p-default max-w-lg mx-auto">
          <h3 className="text-center text-th-black">CONTACT US TODAY</h3>
          <Userform />
        </Box>
        <img className="mt-12 w-12 md:hidden" src={star} alt=""></img>
        <img className="w-24 ml-8 md:hidden" src={star} alt=""></img>
      </div>
    </section>
  );
};

export default Contacts;
