import React from "react";
import Box from "./Box";
import { servicesList } from "../serviceData";
import { Link } from "react-router-dom";

const Services = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <section id="services" className="pt-2">
      <Box styles="bg-about bg-no-repeat bg-cover bg-center h-[480px]"></Box>
      <div className="pt-2 flex flex-wrap justify-center items-stretch gap-2">
        {servicesList &&
          servicesList.map((service, index) => (
            <Link
              to={"/services/" + service.link}
              state={{ link: service.link }}
              onClick={scrollToTop}
              key={index}
            >
              <Box
                styles={"bg-" + service.color + " flex flex-col p-6 max-w-sm"}
              >
                <h2 className="text-center uppercase">{service.title}</h2>
                <div className="mt-8 flex flex-col gap-2">
                  {service.headings &&
                    service.headings.map((heading, index) => (
                      <div className="flex items-center gap-2" key={index}>
                        <img className="w-3" src={service.star} alt=""></img>
                        <p>{heading.title}</p>
                      </div>
                    ))}
                </div>
              </Box>
            </Link>
          ))}
      </div>
    </section>
  );
};

export default Services;
