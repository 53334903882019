import React from "react";
import Box from "./Box";
import circle from "../assets/circle.svg";
import eye from "../assets/eye.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Header = () => {
  return (
    <section id="start" className="pt-2">
      <Box styles="bg-th-grey py-16 flex flex-col gap-y-12 p-default">
        <div className="flex flex-col items-center gap-y-4 lg:gap-y-12">
          <div className="flex items-center gap-x-8">
            <h1>PERSONALIZED</h1>
            <img className="w-24 hidden lg:block" src={circle} alt=""></img>
          </div>
          <img
            className="mr-[10vw] ml-auto w-12 lg:hidden"
            src={circle}
            alt=""
          ></img>
          <div className="flex items-center gap-x-8">
            <img className="w-32 hidden lg:block" src={eye} alt=""></img>
            <h1>SOLUTION</h1>
          </div>
          <img
            className="mr-auto ml-[10vw] w-24 lg:hidden"
            src={eye}
            alt=""
          ></img>
          <div className="flex items-center gap-x-8 w-full justify-center">
            <h1>FOR BUSINESS</h1>
            <div className="hidden lg:block">
              <AnchorLink href="#contacts" className="site-btn">
                Contact us
              </AnchorLink>
            </div>
          </div>
        </div>
        <AnchorLink href="#contacts" className="site-btn mx-auto lg:hidden">
          Contact us
        </AnchorLink>
      </Box>
    </section>
  );
};

export default Header;
