import star from "./assets/star.svg";
import star_black from "./assets/star-black.svg";
import star_grey from "./assets/star-grey.svg";

export const servicesList = [
    {
        title: "Business consulting",
        link: "business-consulting",
        color: "th-yellow",
        star: star,
        subtext: "Baku Enterprise offers tailored business consulting and advisory services through flexible consultations to help navigate your business in the right direction. Our team of experienced consultants provides expert advice and guidance to businesses of all sizes and industries. We offer a range of services tailored to meet the unique needs of each client.",
        headings: [
            {
                title: "Business processes description",
                subtext: "Detailed business process descriptions are essential for businesses to operate effectively, meet their goals, and stay competitive in today's fast-paced business environment.",
                dots: [
                    "Business process mapping: We help businesses understand and document their existing processes, identify inefficiencies, and develop strategies to streamline operations.",
                    "Business process reengineering: Our consultants work with businesses to redesign and optimize their processes, resulting in increased efficiency, productivity, and cost savings.",
                    "Business process automation: We assist businesses in automating their processes using technology solutions, reducing manual tasks, and improving accuracy and speed.",
                    "Business process monitoring and analysis: Our experts provide ongoing monitoring and analysis of business processes to identify opportunities for improvement and optimize performance.",
                    "Change management: We provide guidance on change management to help businesses effectively implement process improvements and ensure a smooth transition for employees.",
                    "Lean Six Sigma: Our team is experienced in the Lean Six Sigma methodology and can provide guidance on its application to improve business processes and achieve operational excellence.",
                ]
            },

            {
                title: "Customized researches",
                subtext: "Our suite of services is customized to cater to the specific requirements of each client, and includes:",
                dots: [
                    "Research planning: We work with businesses to identify their research goals and objectives, and develop a research plan that includes research methodology, sample size, data collection, and analysis.",
                    "Data collection: We collect data using a range of methods, including surveys, interviews, focus groups, and secondary research, ensuring that the data collected is accurate, reliable, and relevant.",
                    "Data analysis: Our team analyzes the data using a range of statistical and analytical techniques, providing businesses with actionable insights that can be used to inform business decisions.",
                    "Report writing and presentation: We write detailed reports that summarize the research findings, providing businesses with a clear understanding of the results. We also present the findings in a clear and concise manner, using visual aids and other tools to enhance understanding.",
                    "Follow-up support: We offer ongoing support to businesses, providing guidance on how to implement the research findings and recommendations.",
                    "Customized research services: We offer customized research services to meet the unique needs of each client, including brand research, customer research, market sizing and forecasting, and competitor analysis.",
                ]
            },

            {
                title: "Business Strategy",
                subtext: "Every business is unique and requires a customized approach to achieve success. Our team is committed to delivering high-quality services and providing ongoing support to ensure long-term success. Contact us today to learn more about how we can help your business achieve its goals and objectives through effective business strategy. Our services include:",
                dots: [
                    "Strategic Planning: We work with businesses to develop a comprehensive and actionable strategic plan that outlines the key initiatives required to achieve their goals and objectives.",
                    "Market Analysis: Our team conducts in-depth market analysis to identify market trends, customer preferences, and competitor behavior, providing businesses with insights to help them make informed business decisions.",
                    "Business Modeling: We help businesses develop a clear understanding of their business model, identifying areas for improvement and optimization.",
                    "Performance Management: We develop performance management systems that enable businesses to track progress toward their goals, identify areas for improvement, and implement corrective actions.",
                    "Change Management: We provide guidance and support to businesses undergoing significant changes, helping them manage the transition and ensure that the changes are successfully implemented.",
                    "Business Growth Strategies: We help businesses identify new growth opportunities and develop strategies to capture them, ensuring long-term sustainability and success.",
                ]
            },

            {
                title: "Enterprise Portfolio and Program Management",
                subtext: "Our team is committed to delivering high-quality services and providing ongoing support to ensure long-term success. Contact us today to learn more about how we can help your business achieve its strategic goals and objectives through effective portfolio and program management.",
                dots: [
                    "Portfolio Management: We work with businesses to identify and prioritize projects and programs that align with their strategic goals and objectives, ensuring that the portfolio is balanced and optimized for success.",
                    "Program Management: We provide end-to-end program management services, from program planning and execution to monitoring and controlling, ensuring that programs are completed on time, within budget, and to the required quality standards.",
                    "Project Management: We provide project management services that cover the entire project lifecycle, including project planning, execution, monitoring, and controlling.",
                    "Change Management: We provide guidance and support to businesses undergoing significant changes, helping them manage the transition and ensure that the changes are successfully implemented.",
                    "Risk Management: We help businesses identify and manage risks associated with their portfolios, programs, and projects, ensuring that risks are mitigated and managed effectively.",
                    "Performance Management: We develop performance management systems that enable businesses to track progress toward their goals, identify areas for improvement, and implement corrective actions.",
                ]
            },

            {
                title: "BPMN",
                subtext: "BPMN is a valuable tool for businesses, as it helps to improve communication, collaboration, and understanding of business processes, ultimately leading to improved efficiency and productivity. Includes several steps:",
                dots: [
                    "Process modeling and design: Our experts use the BPMN standard to create detailed process models that capture the flow, structure, and logic of business processes.",
                    "Process analysis and optimization: We analyze existing processes to identify bottlenecks, inefficiencies, and areas for improvement, and recommend process redesigns to optimize performance.",
                    "Process automation: We assist businesses in automating their processes using BPMN-compatible technology solutions, reducing manual tasks and improving accuracy and speed.",
                    "Process implementation and deployment: Our consultants provide guidance on implementing BPMN models and deploying new processes to ensure smooth adoption by employees.",
                    "Process monitoring and control: We offer ongoing monitoring and control of BPMN-based processes, including tracking key performance indicators (KPIs) and identifying areas for improvement.",
                ]
            },

            {
                title: "RACI",
                subtext: "RACI is an important tool for businesses as it helps to improve clarity, collaboration, and efficiency in business processes, ultimately leading to better decision-making and improved business outcomes. Effective stakeholder engagement and alignment are critical to achieving successful outcomes. Our team is committed to delivering high-quality services and providing ongoing support to ensure long-term success. Contact us today to learn more about how we can help your business optimize its processes using a RACI matrix.",
                dots: [
                    "Process review and analysis: We work with businesses to identify the key processes that require a RACI matrix, and analyze their current workflows and roles.",
                    "Role identification and definition: Our consultants assist businesses in identifying the key roles involved in each process and defining their responsibilities and accountabilities.",
                    "RACI matrix creation: Using the information gathered in the previous steps, we create a detailed RACI matrix that outlines the roles and responsibilities of each stakeholder in the process.",
                    "Stakeholder engagement and alignment: We engage with stakeholders to ensure that they understand their roles and responsibilities as outlined in the RACI matrix, and align on expectations and outcomes.",
                    "Implementation and adoption: We provide guidance on how to implement the RACI matrix within the business processes, and offer ongoing support to ensure successful adoption by employees.",
                    "RACI matrix maintenance and updates: We offer ongoing maintenance and updates to the RACI matrix, ensuring that it remains current and reflects any changes in the process or stakeholder roles.",
                ]
            },

            {
                title: "KPI",
                subtext: "KPIs are a crucial tool for businesses, as they provide a way to measure performance, set goals, and make data-driven decisions, ultimately leading to improved efficiency and success. KPIs can be applied to various areas of a business, such as sales, marketing, operations, finance, and customer service. They are typically linked to specific goals and objectives and are used to track progress toward achieving those goals.",
            },

            {
                title: "Balanced scorecard",
                subtext: "A balanced scorecard is a powerful tool for businesses, as it helps to align activities with strategy, improve performance, and make informed decisions, ultimately leading to greater success. The BSC approach focuses on four key perspectives that are critical to a business's success:",
                dots: [
                    "Financial Perspective: This perspective measures the financial performance of the business, including revenue growth, profitability, return on investment, and cash flow.",
                    "Customer Perspective: This perspective measures the level of customer satisfaction, loyalty, and retention, as well as customer acquisition, market share, and brand reputation.",
                    "Internal Process Perspective: This perspective measures the efficiency and effectiveness of the business's internal processes, including production, operations, and service delivery.",
                    "Learning and Growth Perspective: This perspective measures the business's ability to innovate, improve, and develop its people, systems, and technology.",
                    "The BSC approach helps businesses to balance their focus across these four perspectives, ensuring that they are not only achieving short-term financial goals but also building the capabilities and culture needed to sustain long-term success.",

                ]
            },

            {
                title: "OKR",
                subtext: "OKRs are an important tool for businesses, as they help to align efforts, improve focus, and track progress, ultimately leading to improved performance and greater success. OKRs involve setting specific, measurable, and time-bound objectives that are aligned with the organization's strategic goals. These objectives are then broken down into measurable key results that help to track progress towards achieving the objectives.",
            },
        ],
    },
    {
        title: "Custom software development",
        link: "software-development",
        color: "th-grey",
        star: star_black,
        subtext: "Full-cycle development, from your idea to the final product. We prioritize quality above all else, ensuring our clients derive tangible business value from their IT investments. Our approach comprises healthy project processes, professional and motivated teams, and effective communication between Baku Enterprise, our clients' teams, and stakeholders. By partnering with us, you can be confident that unmanaged risks and unplanned expenditures will be a thing of the past. \n Moreover, we are proud to offer ISO 27001-certified security management that is based on comprehensive policies and processes, advanced security technology, and highly skilled professionals. With our rigorous security measures in place, you can rest assured that your data and IT infrastructure are in safe hands.",
        headings: [
            {
                title: "Software consulting",
                subtext: "Our software consulting services provide expert guidance and support to businesses looking to improve their software development processes and technologies. Our team of experienced consultants has a deep understanding of the latest software development trends, methodologies, and tools and can help businesses to leverage them to achieve their business goals. Our software consulting services cover the following areas:",
                dots: [
                    "Software Development Methodologies: We help businesses to adopt agile software development methodologies that improve collaboration, speed up development cycles, and reduce time-to-market.",
                    "Software Architecture and Design: We provide expert guidance on software architecture and design, helping businesses to build scalable, secure, and reliable software systems that meet their specific requirements.",
                    "Technology Selection and Integration: We help businesses to choose the right technologies for their needs and integrate them seamlessly into their existing systems.",
                    "Quality Assurance and Testing: We provide quality assurance and testing services to ensure that software systems are of the highest quality and meet the user's requirements.",
                    "Performance Optimization: We help businesses to optimize the performance of their software systems, ensuring that they can handle large volumes of data and traffic without slowing down.",
                ]
            },

            {
                title: "Web development",
                subtext: "We have a team of skilled developers and designers who leverage the latest technologies and industry best practices to build high-quality and user-friendly web applications that meet our client's unique needs. Our web development services cover the following areas:",
                dots: [
                    "Custom Web Development: We build custom web applications from scratch that are tailored to meet our client's specific requirements and business objectives.",
                    "E-commerce Development: We develop e-commerce websites and applications that are user-friendly, secure, and scalable. We leverage popular e-commerce platforms like Shopify and WooCommerce to build online stores that drive sales and revenue.",
                    "Content Management Systems: We build custom content management systems that enable businesses to manage their content easily and efficiently. We leverage popular CMS platforms like WordPress and Drupal to build websites that are easy to update and maintain.",
                    "API Development and Integration: We develop APIs and integrate them seamlessly into our client's existing systems. We also integrate third-party APIs to extend the functionality of our client's web applications.",
                    "Web Design: We provide web design services that focus on creating visually appealing and user-friendly interfaces that enhance the user experience and drive engagement.",
                ]
            },

            {
                title: "Mobile development",
                subtext: "",
                dots: [
                    "Native Mobile App Development: We build native mobile applications for iOS and Android platforms that provide high performance and enhanced user experience. We leverage platform-specific technologies and development tools to build apps that are optimized for each platform.",
                    "Cross-Platform App Development: We develop cross-platform mobile applications using popular frameworks like React Native and Xamarin. Our cross-platform development approach enables businesses to build mobile applications that work seamlessly across different platforms, reducing development costs and time-to-market.",
                    "Mobile Web Development: We build mobile web applications that are optimized for mobile devices and provide a seamless user experience across different browsers and devices.",
                    "Mobile App Design: We provide mobile app design services that focus on creating visually appealing and user-friendly interfaces that enhance the user experience and drive engagement.",
                    "App Store Optimization: We provide app store optimization services that help businesses to improve the visibility and discoverability of their mobile applications on app stores.",
                ]
            },

            {
                title: "Desktop development",
                subtext: "We provide end-to-end solutions that cover everything from ideation and design to development and deployment, ensuring that our clients' desktop applications are of the highest quality and meet their business objectives. Our desktop development services cover the following areas:",
                dots: [
                    "Custom Desktop Application Development: We build custom desktop applications from scratch that are tailored to meet our client's specific requirements and business objectives.",
                    "Cross-Platform Desktop Application Development: We develop cross-platform desktop applications using popular frameworks like Electron and Java. Our cross-platform development approach enables businesses to build desktop applications that work seamlessly across different platforms, reducing development costs and time-to-market.",
                    "Desktop App Design: We provide desktop app design services that focus on creating visually appealing and user-friendly interfaces that enhance the user experience and drive engagement.",
                    "Desktop App Modernization: We help businesses to modernize their legacy desktop applications by migrating them to modern platforms and technologies.",
                ]
            },

            {
                title: "Database development",
                subtext: "We have a team of skilled developers and database administrators who leverage the latest technologies and industry best practices to build high-performance and scalable databases that meet our client's unique needs. Our database development services cover the following areas:",
                dots: [
                    "Database Design and Development: We design and develop custom databases that are tailored to meet our client's specific requirements and business objectives. We use industry-standard relational database management systems (RDBMS) like MySQL, Oracle, and SQL Server to build databases that are secure, scalable, and easy to manage.",
                    "Database Optimization: We optimize databases to improve their performance, scalability, and reliability. We use tools and techniques like indexing, partitioning, and query optimization to optimize databases for faster data access and better system performance.",
                    "Database Administration: We provide database administration services that include database backup and recovery, database security management, and database performance monitoring. Our database administrators ensure that databases are always available, secure, and performing optimally.",
                    "Database Migration: We help businesses to migrate their databases to modern platforms and technologies, ensuring minimal downtime and data loss.",
                ]
            },

            {
                title: "Cloud development",
                subtext: "Our cloud development services are tailored to meet the unique needs of each client, and we work closely with businesses to understand their requirements, goals, and constraints. We provide end-to-end solutions that cover everything from cloud application development to infrastructure design and development, migration, and cloud DevOps, ensuring that our clients' cloud solutions are secure, scalable, and perform optimally. Our cloud development services cover the following areas:",
                dots: [
                    "Cloud Application Development: We develop custom cloud-based applications that are tailored to meet our client's specific requirements and business objectives. We use popular cloud platforms like Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform (GCP) to build applications that are scalable, secure, and easy to manage.",
                    "Cloud Infrastructure Design and Development: We design and develop custom cloud infrastructure that supports our client's cloud-based applications. Our cloud architects ensure that the infrastructure is secure, scalable, and fault-tolerant, ensuring that applications run smoothly and reliably.",
                    "Cloud Migration: We help businesses to migrate their existing applications and infrastructure to the cloud, ensuring minimal downtime and data loss. Our cloud migration services include application and data migration, infrastructure migration, and cloud security migration.",
                    "Cloud DevOps: We provide cloud DevOps services that focus on automating cloud infrastructure and application deployment, monitoring, and management. Our cloud DevOps services ensure that cloud applications and infrastructure are always available, secure, and performing optimally.",
                ]
            },

            {
                title: "SaaS development",
                subtext: "We provide end-to-end solutions that cover everything from SaaS application development to architecture design, deployment, management, and integration, ensuring that our clients' SaaS solutions are secure, scalable, and perform optimally. Our SaaS development services cover the following areas:",
                dots: [
                    "SaaS Application Development: We develop custom cloud-based software applications that are tailored to meet our client's specific requirements and business objectives. We use popular cloud platforms like Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform (GCP) to build applications that are scalable, secure, and easy to manage.",
                    "SaaS Architecture Design: We design the architecture of the SaaS solution, ensuring that it is secure, scalable, and fault-tolerant. Our SaaS architects ensure that the application can handle high traffic volumes and is easy to manage.",
                    "SaaS Deployment and Management: We help businesses deploy their SaaS applications and manage them effectively. Our SaaS experts ensure that the application is always available, secure, and performing optimally.",
                    "SaaS Integration: We integrate the SaaS application with other applications or services, ensuring seamless functionality and a better user experience.",
                ]
            },

            {
                title: "Software modernization",
                subtext: "Our software modernization services cover the following areas:",
                dots: [
                    "Legacy Software Assessment: We assess the existing legacy software application and identify areas that require modernization. Our experts evaluate the application's architecture, code quality, performance, security, and maintainability.",
                    "Software Migration: We migrate the existing application to a modern platform or framework, ensuring that the application is compatible with the latest technologies and platforms. We use popular platforms like cloud, mobile, and web to modernize the application.",
                    "Application Re-engineering: We re-engineer the application to improve its performance, scalability, and maintainability. We optimize the application's architecture, code quality, and database structure to make it easier to manage and maintain.",
                    "User Interface Modernization: We update the application's user interface to make it more modern, user-friendly, and responsive. We use the latest design and development practices to improve the user experience.",
                ]
            },

        ],
    },
    {
        title: "HR consulting",
        link: "hr-consulting",
        color: "th-violet",
        star: star_grey,
        subtext: "Cost-effective human resource solutions for start-ups, small, medium, and large organizations. Our team of experienced HR professionals is dedicated to providing expert guidance and support to businesses of all industries.",
        headings: [
            {
                title: "Hiring plan creation",
                subtext: "The full-cycle process from selecting tools and methods for a recruitment process, to determining the requirements for each position. Along with advising on job description preparation and the design of an onboarding process. All of these are based on an analysis of recruitment needs and skill gaps.",
            },

            {
                title: "Recommendations on personnel training conducting",
                subtext: "Personnel training is an important aspect of employee development and organizational success, and conducting regular training programs can lead to improved job performance, enhanced career development, and better overall outcomes.",
            },

            {
                title: "Development of training materials and plans",
                subtext: "Improve job performance and create a better understanding of the team. Providing well-designed and effective training materials and plans can improve employee satisfaction and motivation, leading to higher engagement and improved outcomes.",
            },

            {
                title: "Personnel assessment",
                subtext: "Baku Enterprise helps with a systematic approach to gathering information about individuals. This information is used to make employment or career-related decisions about applicants and employees.",
            },

            {
                title: "Development of corporate policies",
                subtext: "Company policies are created to establish expectations and provide guidance on consistently handling workplace situations. Although most company policies are not all-encompassing, they provide direction regarding what is appropriate and inappropriate or unacceptable behavior. Company policies help maintain order within the organization and ensure that people are treated fairly and equally. Policies also help employees understand what is expected of them.",
            },

        ],
    },
];