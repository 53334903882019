import React from "react";
import Box from "./Box";
import logo from "../assets/logo.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Footer = () => {
  return (
    <section id="footer">
      <Box styles="bg-th-grey p-default flex flex-col gap-y-16 items-center pt-16 pb-8">
        <div className="md:flex md:justify-between md:gap-x-8 w-full">
          <a href="/">
            <img
              className="w-40 md:w-64"
              src={logo}
              alt="BAKU enterprise"
            ></img>
          </a>
          <nav className="footer-nav flex flex-col gap-y-4 items-center md:grid md:grid-cols-2">
            <AnchorLink href="#services">SERVICES</AnchorLink>
            <AnchorLink href="#contacts">CONTACTS</AnchorLink>
            <AnchorLink href="#benefit">FOR BUSINESSES</AnchorLink>
            <a
              href="https://drive.google.com/file/d/18BUV9pELP0E4dAoYO1tRJ-AQEw5xED6c/view?usp=sharing"
              target="_blank"
              rel="nofollow"
            >
              TERMS AND CONDITIONS
            </a>
          </nav>
        </div>
        <p className="text-th-black/50 text-center text-xs">
          Baku Enterprise Limited, Room 3602, Tower 1, Enterprise square Five,
          38 Wang Chiu, Kowloon Bay, Kowloon Hong Kong <br></br>© Baku
          Enterprise Limited, 2023
        </p>
      </Box>
    </section>
  );
};

export default Footer;
