import React from "react";

const Box = ({ styles, children }) => {
  return (
    <div
      className={
        "relative border-2 border-th-black box-border rounded-[20px] w-full " + styles
      }
    >
      {children}
    </div>
  );
};

export default Box;
