import React, { useState } from "react";
import logo from "../assets/logo.svg";
import menu_open from "../assets/menu_open.svg";
import menu_close from "../assets/menu_close.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion, AnimatePresence } from "framer-motion";
import Box from "./Box";
import circle from "../assets/circle.svg";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0,
        staggerDirection: 1,
      },
    },
  };
  const location = useLocation();
  const path = location.pathname;
  return (
    <section id="navbar">
      <Box styles="h-28 flex items-center p-default bg-th-grey">
        <a href="/">
          <img className="w-40" src={logo} alt="BAKU enterprise"></img>
        </a>
        <nav className="nav-menu hidden md:flex items-center ml-auto gap-x-4">
          <AnchorLink className="font-bold" href="#services">
            SERVICES
          </AnchorLink>
          {path !== "/" ? (
            <></>
          ) : (
            <AnchorLink className="font-bold" href="#benefit">
              FOR BUSINESSES
            </AnchorLink>
          )}
        </nav>
        {toggleMenu ? (
          <img
            className="w-10 ml-auto cursor-pointer md:hidden"
            src={menu_close}
            onClick={() => setToggleMenu(false)}
            alt="Close menu"
          ></img>
        ) : (
          <img
            className="w-10 ml-auto cursor-pointer md:hidden"
            src={menu_open}
            alt="Menu"
            onClick={() => setToggleMenu(true)}
          ></img>
        )}
        <AnimatePresence>
          {toggleMenu && (
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: "100%", maxWidth: "100%" }}
              className="absolute w-full top-[120px] right-0 z-50"
            >
              <Box
                styles="header-nav-burger flex flex-col items-center p-default py-16 gap-y-16 bg-th-grey h-[80vh]"
                initial="closed"
                animate="open"
                variants={sideVariants}
              >
                <AnchorLink
                  className="navlink"
                  href="#services"
                  onClick={() => setToggleMenu(false)}
                >
                  SERVICES
                </AnchorLink>
                {path !== "/" ? (
                  <></>
                ) : (
                  <AnchorLink
                    className="navlink"
                    href="#benefit"
                    onClick={() => setToggleMenu(false)}
                  >
                    FOR BUSINESSES
                  </AnchorLink>
                )}
                <img className="mx-auto" src={circle} alt=""></img>
                <AnchorLink
                  href="#contacts"
                  className="site-btn"
                  onClick={() => setToggleMenu(false)}
                >
                  Contact us
                </AnchorLink>
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </section>
  );
};

export default Navbar;
