import FullService from "../components/FullService";
import Contacts from "../components/Contacts";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useLocation } from "react-router-dom";

const ServicePage = () => {
  const location = useLocation();
  const { link } = location.state;
  return (
    <>
      <Navbar />
      <FullService link={link} />
      <Contacts />
      <Footer />
    </>
  );
};

export default ServicePage;
