import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Services from "../components/Services";
import Benefit from "../components/Benefit";
import Contacts from "../components/Contacts";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <>
      <Navbar />
      <Header />
      <Services />
      <Benefit />
      <Contacts />
      <Footer />
    </>
  );
};

export default Home;
