import React from "react";
import Box from "./Box";
import arrow from "../assets/arrow.svg";
import pic from "../assets/benefit.png";
import star from "../assets/big_star_purple.svg";
import eye from "../assets/eye-rotate.svg";
import circle from "../assets/circle.svg";

const Benefit = () => {
  return (
    <section id="benefit" className="pt-2 overflow-x-hidden overflow-y-visible">
      <Box styles="bg-th-primary md:flex">
        <div className="p-6">
          <h1 className="text-th-grey">BUSINESSES</h1>
          <h1 className="text-th-grey">THAT WILL</h1>
          <h1 className="text-th-grey">BENEFIT</h1>
          <img className="mt-2 w-12" src={arrow} alt=""></img>
        </div>
        <img
          className="-mb-36 min-w-full mt-12 md:hidden"
          src={pic}
          alt=""
        ></img>
        <img
          className="hidden md:block w-1/2 -mb-24 mt-4"
          src={pic}
          alt=""
        ></img>
        <img className="absolute top-48 right-4 w-16" src={star} alt=""></img>
      </Box>
      <div className="md:grid md:grid-cols-4 md:gap-x-2">
        <Box styles="mt-36 px-8 py-16 bg-th-grey md:col-span-3 flex items-center justify-center">
          <h2 className="text-center">OPERATIONAL DIRECTORS</h2>
        </Box>
        <Box styles="mt-2 md:mt-36 p-8 flex items-center justify-center bg-th-grey">
          <img className="md:max-w-[160px]" src={eye} alt=""></img>
        </Box>
      </div>
      <div className="md:grid md:grid-cols-5 md:gap-x-2">
        <Box styles="bg-benefit-first bg-no-repeat bg-cover bg-center h-[240px] mt-2 md:col-span-2"></Box>
        <Box styles="mt-2 px-8 py-16 bg-th-yellow md:col-span-3 flex items-center justify-center">
          <h2 className="text-center text-th-primary">BUSINESS DEVELOPERS</h2>
        </Box>
      </div>
      <div className="md:grid md:grid-cols-5 md:gap-x-2">
        <Box styles="mt-2 px-8 py-16 bg-th-violet md:col-span-3 flex items-center justify-center">
          <h2 className="text-center text-th-black">DEPARTMENT HEAD</h2>
        </Box>
        <Box styles="bg-benefit-second bg-no-repeat bg-cover bg-center h-[240px] mt-2 md:col-span-2"></Box>
      </div>
      <div className="md:grid md:grid-cols-6 md:gap-x-2">
        <Box styles="mt-2 px-8 py-16 flex items-center justify-center bg-th-grey">
          <img className="w-28" src={circle} alt=""></img>
        </Box>
        <Box styles="mt-2 px-8 py-16 bg-th-primary md:col-span-5 flex items-center justify-center">
          <h2 className="text-center text-th-grey">
            HR AND C-LEVEL MANAGEMENT
          </h2>
        </Box>
      </div>
    </section>
  );
};

export default Benefit;
