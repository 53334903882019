import React from "react";
import { servicesList } from "../serviceData";
import Box from "./Box";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import arrow from "../assets/accordion-arrow.svg";
import eye from "../assets/eye-rotate.svg";
import circle from "../assets/circle.svg";

const FullService = ({ link }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const currentService = servicesList.find((service) => service.link === link);
  return (
    <section id="services">
      <Box styles="bg-about-service bg-no-repeat bg-cover bg-center h-[480px]"></Box>
      <Box
        styles={
          "bg-" +
          currentService.color +
          " relative flex flex-col justify-center pt-16 pb-32 px-8 md:px-16 lg:px-48"
        }
      >
        <img className="hidden xl:block absolute left-8 w-48" src={eye} alt=""></img>
        <img className="hidden xl:block absolute bottom-16 right-16 w-48" src={circle} alt=""></img>
        <div className="relative flex items-start justify-center gap-8">
          <Link to="/">Home/</Link>
          <h1 className="text-black uppercase">{currentService.title}</h1>
        </div>
        <p className="relative pt-8 md:pt-16 max-w-3xl mx-auto text-base md:text-lg">
          {currentService.subtext}
        </p>
        <div className="py-8 md:py-16 relative">
          <Accordion allowZeroExpanded className="mx-auto max-w-3xl w-full">
            {currentService.headings &&
              currentService.headings.map((item, index) => (
                <AccordionItem key={index}>
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className={"bg-" + currentService.color + " py-4 md:py-6"}
                    >
                      <div className="flex items-center gap-2">
                        <img
                          className="w-3"
                          src={currentService.star}
                          alt=""
                        ></img>
                        <p className="text-lg md:text-xl font-normal">
                          {item.title}
                        </p>
                        <img className="w-3 ml-auto" src={arrow} alt=""></img>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="flex flex-col items-center gap-4">
                      {item.subtext && (
                        <p className="text-base">{item.subtext}</p>
                      )}
                      {item.dots && (
                        <ul className="list-outside list-disc mt-4">
                          {item.dots.map((dot, index) => (
                            <li
                              className="text-sm md:text-base py-2"
                              key={index}
                            >
                              {dot}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
          </Accordion>
        </div>
      </Box>
      <div className="pt-2 flex flex-wrap justify-center items-stretch gap-2">
        {servicesList &&
          servicesList
            .filter((service) => service.link !== currentService.link)
            .map((service, index) => (
              <Link
                to={"/services/" + service.link}
                state={{ link: service.link }}
                onClick={scrollToTop}
                key={index}
              >
                <Box
                  styles={"bg-" + service.color + " flex flex-col p-6 max-w-md h-full"}
                >
                  <h2 className="text-center uppercase">{service.title}</h2>
                  <div className="mt-8 flex flex-col gap-2">
                    {service.headings &&
                      service.headings.map((heading, index) => (
                        <div className="flex items-center gap-2" key={index}>
                          <img className="w-3" src={service.star} alt=""></img>
                          <p>{heading.title}</p>
                        </div>
                      ))}
                  </div>
                </Box>
              </Link>
            ))}
      </div>
    </section>
  );
};

export default FullService;
